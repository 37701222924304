<template>
    <b-row class="justify-content-center">
        <b-col sm="12" md="10" v-if="paket">
            <section v-if="paket && paket.rasionalisasi_type === 'utbk'">
                <result-utbk :result="result" />
            </section>
            <section v-if="paket && paket.rasionalisasi_type === 'mandiri'">
                <result-mandiri :result="result" />
            </section>
            <section v-if="paket && paket.rasionalisasi_type === 'sma'">
                <result-sma :result="result" />
            </section>
            <section v-if="paket && paket.rasionalisasi_type === 'kedinasan'">
                <result-kedinasan :result="result" />
            </section>
            <section v-if="paket && paket.rasionalisasi_type === 'bintara'">
                <result-bintara :result="result" />
            </section>
        </b-col>
    </b-row>
</template>
<script>
import {BRow, BCol} from 'bootstrap-vue'
import ResultUtbk from './utbk.vue'
import ResultMandiri from './mandiri.vue'
import ResultSma from './sma.vue'
import ResultKedinasan from './kedinasan.vue'
import ResultBintara from './bintara.vue'

export default {
    components: {
        BRow, BCol, ResultUtbk, ResultMandiri, ResultSma, ResultKedinasan, ResultBintara
    },
    data: () => ({
        result: null,
        paket: null
    }),
    methods: {
        async getAuthor(result) {
            try {
                const userId = result.id || result.user_id
                const response = await this.$store.dispatch('pengguna/detail', userId)
                const siswa = response.data
                this.$store.commit('rasionalisasi/SET_CURRENT_SISWA', siswa)
            }
            catch(e) {
                this.displayError(e)
                return false
            }
        },
        
    },
    created() {
        const jadwal = this.$store.state.rasionalisasi.currentJadwal
        const paket  = this.$store.state.rasionalisasi.currentPaket
        const result = this.$store.state.rasionalisasi.currentResult
        if(!jadwal || !paket || !result) {
            this.$router.push(`/master-admin/jadwal-admin/report/${this.$route.params.jadwal_id}`)
        }

        this.result = result
        this.paket = paket
        this.getAuthor(result)
        
    }
}
</script>